
import {defineComponent, ref} from 'vue';
import {TPDataGridApi} from '@tp/vue3-data-components';
import axios from 'axios';
import store from '@/store';
import { truncate } from 'cypress/types/lodash';


export default defineComponent({
    name: 'LogsRecords',
    components: {TPDataGridApi},
    setup ()
    {   
        const allowed = ref(true);
        const URL = process.env.VUE_APP_IDENTITY_URL;
        let accessToken = store.getters['user/getAccessTokens'].identity;
        const api = axios.create({
            baseURL: `${URL}api/logs`,
            withCredentials:true,
            headers : {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        const configDataGrid = {
            columns : [{ 
                name: 'id', 
                title: 'ID',
                orderBy: false,
                conditionType: 'text',
                print: (row: any) => row.id  
            },
            { 
                name: 'applicationId', 
                title: 'Application ID',
                orderBy: true,
                conditionType: 'text',
                print: (row: any) => row.applicationId  
            },
            { 
                name: 'username', 
                title: 'User',
                orderBy: true,
                conditionType: 'text',
                print: (row: any) => row.username  
            },
            { 
                name: 'type', 
                title: 'Type',
                orderBy: true,
                conditionType: 'text',
                print: (row: any) => row.type  
            },
            { 
                name: 'message', 
                title: 'Message',
                orderBy: false,
                conditionType: 'text',
                print: (row: any) => row.message  
            },
            { 
                name: 'createdAt', 
                title: 'Date',
                orderBy: true,
                conditionType: 'date',
                print: (row: any) => 
                {
                    const dateParsed = Date.parse(row.createdAt);
                    return new Date(dateParsed)
                        .toLocaleString();
                }  
            }],
            pageSize: 20,
            api
        };



        return {configDataGrid, allowed};
    }
});
